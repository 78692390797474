import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import * as styles from "../../styles/FeaturePage/SignUp.module.scss";
import { Link } from "gatsby";

const SignUp = () => {
  return (
    <Box>
      <Container>
        <Box className={styles.signUpWrapper}>
          <Box className={styles.signUpContent}>
            <Typography variant="h2">
              Start using your application in less than 2 weeks!
            </Typography>
            <Box>
              <Box className={styles.formWrapper}>
                <Link to="/contact-us/">
                  <Button className={styles.submitEmailBtn}>Contact Now</Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SignUp;
