import React from "react";
import FreeDemo from "../Components/Common/FreeDemo";
import HeroSection from "../Components/Common/HeroSection";
import Layout from "../Components/Common/Layout";
import Everything from "../Components/FeaturePage/Everything";
import Functionalities from "../Components/FeaturePage/Functionalities";
import Platform from "../Components/FeaturePage/Platform";
import SignUp from "../Components/FeaturePage/SignUp";
import Seo from "../Components/seo";
import { Helmet } from "react-helmet";

// Images
import FeatureImage from "../images/featureImage.svg";
import Cross from "../images/CrossPlatform.svg";
import Personal from "../images/Personalization.svg";
import Appeal from "../images/Appealing.svg";
import Digital from "../images/DigitalContent.svg";
import Gateways from "../images/Gateways.svg";

const features = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:standard" />
      </Helmet>
      <Seo
        title="Dynamic yet Effective - Quickxi Features "
        description="Get a cross-platform experience with cost efficiency and seamless performance. Our dynamic features are all that you need right now!"
        tag="index"
        pageUrl="features/"
      />
      <HeroSection
        title="Discover what Quickxi has in store for you"
        subTitle="Quickxi aims to help businesses provide the best customer experience. So what are you waiting for? Get branded & fully functional apps with intuitive features to use right away."
        buttonText="Hook me up"
        image={FeatureImage}
      />
      <Everything />
      <FreeDemo
        title="Are you looking for a quick and easy food delivery application for your restaurant?"
        titleColor="#FFFFFF"
        textSize="30px"
        buttonText="Request a free demo"
        backgroundColor="white"
        contentBGColor="#303030"
        buttonBGColor="#FDAC33"
        buttonTextColor="#000000"
        height="190px"
        width="900px"
        textWidth="514px"
        justify="space-between"
        rowGap="0px"
      />
      <Platform
        heading="Features at a glance"
        subHeading="A glimpse of our main & dynamic features to get you started but the list doesn’t end here!"
        title="Giving your customers a great cross-platform experience"
        description="Our ready-made application solutions are built with cross-platform frameworks to suit multiple operating systems whether it’s Android or iOS."
        points={[
          "Cost-efficiency",
          "No added investments",
          "Seamless performance",
          "Device accessibility",
          "High customer satisfaction",
        ]}
        image={Cross}
      />
      <Platform
        title="Customization options for 
        personalization and more"
        description="Looking to maintain your brand consistency through custom fonts, logos, & colors, or need a new feature integration? Quickxi offers you full design & functionality freedom."
        points={[
          "Improve app engagement rate",
          "Build strong brand identity",
          "Boost your business revenue",
          "Promote trust among your audience",
          "Increase your brand awareness",
        ]}
        direction="row-reverse"
        image={Personal}
      />
      <Platform
        title="Scale your business with flexible payment gateways"
        description="Our ready-made application solutions are built with cross-platform frameworks to suit multiple operating systems whether it’s Android or iOS."
        points={[
          "Multiple payment methods integration",
          "Facilitate your expansion",
          "Less turnover, more customer conversion rates",
          "Promote inclusivity",
          "Retain existing customer base",
        ]}
        image={Gateways}
      />
      <SignUp />
      <Platform
        title="Comprehensive CMS to seamlessly manage your digital content"
        description="Our top notch CMS integration will enable you to effortlessly add, delete, edit, and publish products, food menu, grocery items and services. So modify your app your way!"
        points={[
          "Manage your application without technical knowledge",
          "Cut down the additional costs of maintaining your app",
          "Take control of your content",
          "Easy CMS access through the web browser",
          "Collaborate with multiple content managers",
        ]}
        direction="row-reverse"
        image={Digital}
      />
      <Platform
        title="Visually appealing & intuitive designs"
        description="Quickxi’s mobile app interfaces are developed keeping the latest UI/UX design trends in mind. In addition, high design responsiveness is just a cherry on top."
        points={[
          "Multiple payment methods integration",
          "Facilitate your expansion",
          "Less turnover, more customer conversion rates",
          "Promote inclusivity",
          "Retain existing customer base",
        ]}
        image={Appeal}
      />
      <Functionalities titleOne="Other basic functionalities" />
      <FreeDemo
        title="Ready to grow your business with Quickxi?"
        titleColor="#000000"
        textSize="40px"
        buttonText="Request a free demo"
        backgroundColor="#E2FBF4"
        buttonBGColor="#24D18E"
        buttonTextColor="#FFFFFF"
        height="125px"
        direction="column"
        textAlign="center"
        justify="center"
        rowGap="30px"
      />
    </Layout>
  );
};

export default features;
