import React from "react";
import { Container, Box, Typography } from "@mui/material";
import * as styles from "../../styles/FeaturePage/Platform.module.scss";

const Platform = ({
  heading,
  subHeading,
  title,
  description,
  points,
  direction,
  image,
}) => {
  return (
    <Container>
      <Box className={styles.platformHeading}>
        <Typography variant="h2">{heading}</Typography>
        <Typography variant="h6">{subHeading}</Typography>
      </Box>
      <Box
        className={styles.platformWrapper}
        sx={{
          flexDirection: `${direction}`,
          "@media screen and (max-width:992px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box className={styles.platformContent}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="h6">{description}</Typography>
          <Box className={styles.platformList}>
            <ul>
              {points.map((content) => (
                <li>{content}</li>
              ))}
            </ul>
          </Box>
        </Box>
        <Box className={styles.platformImage}>
          <img loading="lazy" src={image} alt="cross-platform" />
        </Box>
      </Box>
    </Container>
  );
};

export default Platform;
