import React from "react";
import { Container, Box, Typography } from "@mui/material";
import AppCard from "../Common/AppCard";
import EverythingImage from "../../images/EverythingImage.png";

import * as styles from "../../styles/FeaturePage/Everything.module.scss";

const Everything = (props) => {
  const cardContent = [
    {
      title: "Restaurant food ordering apps",
      description:
        "Own a small cafe around the corner? Boost your sales through your custom-owned ready made app and say no to third-party delivery services.",
    },
    {
      title: "Grocery delivery apps",
      description:
        "Simplify grocery shopping for your customers with the smartest and fastest cross-platform application - say no to losing customers.",
    },
    {
      title: "Online shopping apps",
      description:
        "Not enough budget to get your online store up & running? Quickxi got your back with its pocket-friendly solution - say yes to expense management.",
    },
    {
      title: "Apps for on-demand services",
      description:
        "Want to reach a wider audience but something is holding you back? With Quickxi, offer on-demand services irrespective of the geo-location - say yes to accessibility.",
    },
  ];
  return (
    <section className={styles.everythingWrapper}>
      <Container>
        <Box className={styles.everythingText}>
          <Typography variant="h2">There’s an app for everything</Typography>
        </Box>
        <Box className={styles.everythingContent}>
          <Box className={styles.everythingImage}>
            <img loading="lazy" src={EverythingImage} alt="app" />
          </Box>
          <Box className={styles.everythingCard}>
            {cardContent.map((content) => (
              <AppCard
                title={content.title}
                description={content.description}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Everything;
