import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Tracking from "../../images/tracking.svg";
import Cart from "../../images/Cart.svg";
import Map from "../../images/YellowMap.svg";
import Notify from "../../images/PurpleNotification.svg";
import Order from "../../images/PinkOrder.svg";
import Ratings from "../../images/Ratings.svg";
import Search from "../../images/Search.svg";
import Filter from "../../images/FilterProducts.svg";
import List from "../../images/ProductLists.svg";
import History from "../../images/ProofHistory.svg";
import Faq from "../../images/Faq.svg";
import Support from "../../images/ChatSupport.svg";
import FunctionalCard from "../Common/FunctionalCard";

import * as styles from "../../styles/FeaturePage/Functionalities.module.scss";

const Functionalities = (props) => {
  const cardsContent = [
    {
      text: "Live tracking",
      image: Tracking,
      border: "1px solid #24D18E",
      hoverColor: "#24D18E33",
      align: "center"
    },
    {
      text: "Shopping cart",
      image: Cart,
      border: "1px solid #837BFC",
      hoverColor: "#837BFC33",
      align: "center"
    },
    {
      text: "Map integration",
      image: Map,
      border: "1px solid #F8E044",
      hoverColor: "#F8E04433",
      align: "center"
    },
    {
      text: "Push notifications",
      image: Notify,
      border: "1px solid #6588FA",
      hoverColor: "#6588FA33",
      align: "center"
    },
    {
      text: "Order history",
      image: Order,
      border: "1px solid #FB79AF",
      hoverColor: "#FB79AF33",
      align: "center"
    },
    {
      text: "Reviews & ratings",
      image: Ratings,
      border: "1px solid #2EC6F6",
      hoverColor: "#2EC6F633",
      align: "center"
    },
    {
      text: "Search functionality",
      image: Search,
      border: "1px solid #24D18E",
      hoverColor: "#24D18E33",
      align: "center"
    },
    {
      text: "Filter products",
      image: Filter,
      border: "1px solid #C457FA",
      hoverColor: "#C457FA33",
      align: "center"
    },
    {
      text: "Unlimited product lists",
      image: List,
      border: "1px solid #2EC6F6",
      hoverColor: "#2EC6F633",
      align: "center"
    },
    {
      text: "Proof of history",
      image: History,
      border: "1px solid #F8E044",
      hoverColor: "#F8E04433",
      align: "center"
    },
    {
      text: "FAQs",
      image: Faq,
      border: "1px solid #837BFC",
      hoverColor: "#837BFC33",
      align: "center"
    },
    {
      text: "Chat support",
      image: Support,
      border: "1px solid #24D18E",
      hoverColor: "#24D18E33",
      align: "center"
    },
  ];
  return (
    <Box className={styles.functionalWrapper}>
      <Container>
        <Box className={styles.functionalHeading}>
          <Typography variant="h2">{props.titleOne}</Typography>
        </Box>
        <Box className={styles.functionalCardsWrapper}>
          {cardsContent.map((content) => (
            <FunctionalCard
              text={content.text}
              image={content.image}
              border={content.border}
              hoverColor={content.hoverColor}
              width={content.width}
              height={content.height}
              align={content.align}
              padding={content.padding}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Functionalities;
