import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import * as styles from "../../styles/Common/FreeDemo.module.scss";
import { Link } from "gatsby";

const FreeDemo = (props) => {
  return (
    <section
      className={styles.freeDemoWrapper}
      style={{
        backgroundColor: `${props.backgroundColor}`,
      }}
    >
      <Container>
        <Box
          className={styles.freeDemoContent}
          sx={{
            backgroundColor: `${props.contentBGColor}`,
          }}
        >
          <Box
            className={styles.freeDemoText}
            sx={{
              flexDirection: `${props.direction}`,
              maxWidth: `${props.width}`,
              justifyContent: `${props.justify}`,
              rowGap: `${props.rowGap}`,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                textAlign: `${props.textAlign}`,
                color: `${props.titleColor}`,
                fontSize: `${props.textSize}`,
                maxWidth: `${props.textWidth}`,
              }}
            >
              {props.title}
            </Typography>
            <Link to="/contact-us/">
              <Button
                sx={{
                  backgroundColor: `${props.buttonBGColor}`,
                  color: `${props.buttonTextColor}`,
                  "&:hover": {
                    backgroundColor: `${props.buttonBGColor}`,
                  },
                }}
              >
                {props.buttonText}
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default FreeDemo;
